import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { FileTransferService, IFileData } from '@services/file-transfer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// import { SnackbarService } from '@impl/services/snackbar/snackbar.service';

type HtmlType = 'button' | 'submit' | 'reset'; // origin html type MDN
type ButtonType = 'button' | 'file';

type StyleType = 'button' | 'flat-button' | 'raised-button' | 'mini-fab' | 'icon-button';
type ColorType = 'default' | 'primary' | 'accent' | 'warn';
type SizeType = 'default' | 'small' | 'large';

@UntilDestroy()
@Component({
  selector: 'app-universal-button',
  templateUrl: './universal-button.component.html',
  styleUrls: ['./universal-button.component.scss'],
})
export class UniversalButtonComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  // params
  @Input() htmlType: HtmlType = 'button';
  @Input() btnType: ButtonType = 'button';
  @Input() acceptFileType = 'image/*';
  @Input() btnTitle: string | number;
  @Input() disabled: boolean; // *after disable use emitClick event
  @Input() tooltip: string;

  // style
  @Input() size: SizeType = 'default';
  @Input() btnTitleSuffix: string;
  @Input() wrapperCssClass: string;
  @Input() btnCssClass: string;
  @Input() styleType: StyleType = 'raised-button';
  @Input() color: ColorType = 'primary';
  @Input() icon: string; // https://material.io/resources/icons/?style=baseline
  @Input() svgIcon: string; // register at first
  // Use css class for wrapper like  <app-universal-button class="btn-holder"></app-universal-button>

  // emitters
  @Output() emitClick = new EventEmitter<any>();
  @Output() emitFileData = new EventEmitter<IFileData>();

  constructor(
    private fileSvc: FileTransferService, // private snackbarSvc: SnackbarService,
  ) {}

  ngOnInit() {}

  onClick(event) {
    this.emitClick.emit(event);
    if (this.btnType === 'file') {
      this.fileInput.nativeElement.click();
    }
  }

  onFileChanged(ev) {
    // this.emitData.emit(ev);
    this.fileSvc
      .onFileSelected(ev)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IFileData) => {
          if (data) this.emitFileData.emit(data);
          this.fileInput.nativeElement.value = '';
        },
        // (err: IFileDataError) => {
        //   err.code === 1 && this.snackbarSvc.warning('Please, select file with valid type.');
        // }
      );
  }

  ngOnDestroy() {}
}
