export enum QueryParamsProperty {
  FILTERS = 'filters',
  ACTIVE_TAB = 'activeTab',
  QUERY_PARAMS = 'queryParams',
}

export enum Page {
  MEMBERS_PROFILE = '[Members Profile]',
  MEMBERS_STATUS = '[Members Status]',

  POST_VIEWS = '[Post Views]',

  ROOT_STATUSES = '[Root Statuses]',

  ROOT_USERS = '[Root Users]',
  USERS_MY_PEOPLE = '[Users MyPeople]',
  USERS_FOLLOWERS = '[Users Followers]',
  USERS_FOLLOWINGS = '[Users Followings]',
  USERS_LISTS = '[Users Lists]',
  USERS_ITEMS = '[Users Items]',

  ROOT_WAITLIST_USERS = '[Root WaitlistUsers]',
  WAITLIST_USER_STATUS = '[WaitlistUser Status]',

  ROOT_FAV_LISTS = '[Root FavLists]',

  ROOT_FAV_LIST_ITEM = '[Root FavList Item]',
  ROOT_ADMINS = '[Root Admins]',

  ROOT_STARTER_KITS = '[Root StarterKits]',

  ROOT_PREFILL = '[Root Prefill]',
  ROOT_PREFILL_HISTORY = '[Root PrefillHistory]',
}

export type Params = { [key in Partial<QueryParamsProperty>]: QueryParamsValue };

export interface QueryParamsValue {
  [key: string]: any;
}
