import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

type InputType = 'checkbox' | 'toggle';
type LabelPosition = 'before' | 'after';

@Component({
  selector: 'app-universal-toggle',
  templateUrl: './universal-toggle.component.html',
  styleUrls: ['./universal-toggle.component.scss'],
})
export class UniversalToggleComponent implements OnInit {
  @Input() fControl: UntypedFormControl | AbstractControl;
  @Input() inputType: InputType = 'toggle';

  // style
  @Input() label: string;
  @Input() labelPosition: LabelPosition = 'after';
  @Input() wrapperCssClass: string;

  constructor() {}

  ngOnInit() {}
}
