import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  /**
   *
   *
   *
   */
  transform(arr?: any[], prop?: string, join?: string): unknown {
    if (!arr || !prop) return arr;
    const mapped = arr.map((item) => item[prop]);
    return join ? mapped.join(join) : mapped;
  }
}
