import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { SnackbarService } from '@services/snackbar';
import { AuthService } from '@services/auth';
import { HttpStatus, SnackbarMessage } from '@const';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbarSvc: SnackbarService,
    private authSvc: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('HTTP interceptor ERROR', error);

        let message = SnackbarMessage.ERROR_DEFAULT;

        if (!window.navigator.onLine) {
          // Network error occurred.
          message = SnackbarMessage.NO_NETWORK;
        } else if (error.error) {
          message = error.error.message;
          const statusCode = error.error.statusCode;

          // expired or invalid token
          if (statusCode === HttpStatus.UNAUTHORIZED) {
            this.authSvc.logout();
            if (/(expired)/i.test(message)) message = SnackbarMessage.SESSION_EXPIRED;
            if (/(No auth token)/.test(message)) return throwError(error);
          }

          // not found route or bed request
          if ([HttpStatus.INTERNAL_SERVER_ERROR].includes(statusCode)) {
            message = SnackbarMessage.ERROR_DEFAULT;
          }
        }

        this.snackbarSvc.error(message);
        return throwError(error);
      }),
    );
  }

  /**
   * Custom ErrorHandler to increase UX
   * will fire only in case of Chunk error(core error on No Internet)
   */
  handleError(error) {
    if (!error.error) {
      console.error('custom core ERROR', error);

      if (window.navigator.onLine) {
        this.snackbarSvc.error(`${SnackbarMessage.ERROR_DEFAULT} <br> ${SnackbarMessage.NEEDS_RELOAD}`, 20000);
      } else {
        // if (error+'').includes('Error: Loading chunk')
        this.snackbarSvc.error(SnackbarMessage.NO_NETWORK, 6000);
      }
    }
    return throwError(error);
  }
}
