<div class="breadcrumbs card f-c-sb">
  <div class="f-c-c">
    <!-- BACK BTN -->
    <app-universal-button
      *ngIf="showBack"
      styleType="icon-button"
      icon="arrow_back_ios"
      class="back-btn"
      (click)="goBack()"
    ></app-universal-button>

    <!--  TITLE  -->
    <div>
      <h2>{{ title }}</h2>
      <ng-content></ng-content>
    </div>
  </div>

  <!--  CTRL BUTTON generator -->
  <div class="ctrl-block f-c-c">
    <ng-container *ngFor="let btn of buttons">
      <app-universal-button
        styleType="raised-button"
        [icon]="btn.icon"
        [btnTitle]="btn.title"
        [color]="btn.color"
        (emitClick)="onClickBtn(btn)"
        [disabled]="btn.disabled"
        [tooltip]="btn.tooltip"
      ></app-universal-button>
    </ng-container>
  </div>
</div>
