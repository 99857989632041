<div class="">
  <div class="table-wrapper">
    <table mat-table [dataSource]="[]" matSort>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>title</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="field">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>field</th>
        <td mat-cell *matCellDef="let element">{{ element.field }}</td>
      </ng-container>

      <ng-container matColumnDef="another">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>another</th>
        <td mat-cell *matCellDef="let element">{{ element.another }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | moment: 'll' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['title', 'field', 'another', 'createdAt']"></tr>
      <tr mat-row *matRowDef="let row; let element; columns: ['title', 'field', 'another', 'createdAt']"></tr>
    </table>
  </div>
  <p class="stub-msg">{{ isNotImplemented ? StubMsg.NOT_IMPLEMENTED : StubMsg.NOT_FOR_MVP }}</p>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
