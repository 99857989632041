export interface UploadFileResp {
  destination: string;
  encoding: string;
  fieldname: string;
  filename: string;
  mimetype: string;
  originalname: string;
  path: string;
  fullPath: string;
  size: number;
}

export interface UploadImgResp {
  img: string;
}
