import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IFileData } from './file.interface';

@Injectable({
  providedIn: 'root',
})
export class FileTransferService {
  private _fileSubject: BehaviorSubject<IFileData>;
  private _fileData: IFileData = {
    base64: null,
    file: null,
    files: [],
  };

  constructor() {}

  /**
   * @description
   * Needs sanitizer for image (if not base64) because of CORS
   *
   * upload via input type="file"
   *
   * @usage
   * ```html
   *
   * <input type="file" (change)="fileSvc.onFileSelected($event))"
   *     accept="application/pdf">
   * ```
   *
   * ```ts
   * this.fileSvc.onFileSelected(ev).subscribe((data: IFileData) => {
   *          this.avatarImage = data.file.url; // base64
   *          this.formGroup.get('avatarFileName').setValue(data.file);
   *      })
   * ```
   */
  onFileSelected(ev): BehaviorSubject<IFileData> {
    this._fileSubject = new BehaviorSubject<IFileData>(null);

    if (ev.target.files && ev.target.files.length) {
      const acceptTypes: string[] = ev.target.accept.split(',');
      const files = ev.target.files;
      const filesArr = [];

      // Validation uses only for first file! In case of using arr you should upd validator!
      if (this.isFileTypeValid(acceptTypes, files[0].type)) {
        // array from pseudo array e.target.files
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < files.length; i++) {
          // set file path
          files[i].url = URL.createObjectURL(files[i]);
          filesArr.push(files[i]);
        }

        // set file data
        this._fileData.file = files[0];
        this._fileData.files = filesArr;

        // // set base64 if image
        // if (files[0].type === 'image/jpeg') {
        //   const reader = new FileReader();
        //   reader.onloadend = () => {
        //     this._fileData.base64 = reader.result;
        //     this._fileSubject.next(this._fileData);
        //   };
        //   reader.readAsDataURL(files[0]);
        // }

        // pass data
        this._fileSubject.next(this._fileData);
      } else {
        this._fileSubject.error({ code: 1 });
      }
    }
    return this._fileSubject;
  }

  // compare example file.type: image/jpeg to ['image/png', 'image/jpeg', 'video/*']
  private isFileTypeValid(acceptTypes: string[], fileTypeInfo: string): boolean {
    const fileType = fileTypeInfo.split('/')[0]; // image, video etc.
    const fileFormat = fileTypeInfo.split('/')[1]; // mp4, jpeg, pdf etc.

    return acceptTypes.some((acceptTypeInfo) => {
      const accType = acceptTypeInfo.split('/')[0];
      const accFormat = fileTypeInfo.split('/')[1];
      return accType === fileType && (accFormat === fileFormat || accFormat === '*');
    });
  }
}
