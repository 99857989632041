import { Page } from '@interfaces';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QueryParamsState, selectEntities } from './query-params.reducer';

const selectQueryParamsState = createFeatureSelector<QueryParamsState>('query-params');

export const selecEntities = createSelector(selectQueryParamsState, selectEntities);

export const selectActivePage = createSelector(selectQueryParamsState, (state: QueryParamsState) => state.activePage);

export const selectCurrentPageQueryParams = createSelector(
  selecEntities,
  selectActivePage,
  (queries: Dictionary<any>, page: Page) => queries[page]?.params,
);
