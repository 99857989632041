import { Pipe, PipeTransform } from '@angular/core';

enum SecondsIn {
  SECOND = 1,
  MINUTE = 60,
  HOUR = 3600,
}

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(ms: number): string {
    let secLeft = Math.floor(ms / 1000);
    // const minutes: number = Math.floor(sec / 60);
    // return minutes + ':' + Math.round(sec - minutes * 60);

    const str = '';

    const calc = (seconds: SecondsIn) => {
      if (secLeft < seconds) return '00';
      const amount = Math.floor(secLeft / seconds);
      secLeft = secLeft - amount * seconds;
      return amount < 10 ? '0' + amount : amount;
    };

    return `${calc(SecondsIn.HOUR)}:${calc(SecondsIn.MINUTE)}:${calc(SecondsIn.SECOND)}`;

    // if (secLeft >= SecondsIn.HOUR) {
    //   const hours = Math.floor(secLeft / SecondsIn.HOUR);
    //   secLeft = secLeft - (hours * SecondsIn.HOUR);
    //   str = str + (hours === 1 ? `${hours} hour ` : ` ${hours} hours `);
    // }
    // str = str + (secLeft === 1 ? `${secLeft} sec ` : ` ${secLeft} seconds `);
    //
    // return str;
  }
}
