import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-link-modal',
  templateUrl: './add-link-modal.component.html',
  styleUrls: ['./add-link-modal.component.scss'],
})
export class AddLinkModalComponent implements OnInit {
  form: UntypedFormGroup = this.formBuilder.group({
    link: ['', [Validators.required, Validators.pattern(this.validURLPattern)]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialogRef: MatDialogRef<AddLinkModalComponent>,
  ) {}

  ngOnInit() {}

  submit(): void {
    if (this.form.invalid) return;
    const { link } = this.form.value;
    this.form.value.link = this.handleLink(link);
    this.matDialogRef.close(this.form.value);
  }

  close(): void {
    this.matDialogRef.close();
  }

  private get validURLPattern(): RegExp {
    // A basic pattern for URL validation, adjust as necessary
    return /^(http|https):\/\/[^ "]+$/;
  }

  private handleLink(link: string): string {
    return /^(http|https)\:\/\//.test(link) ? link : `http://${link}`;
  }
}
