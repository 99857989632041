export interface IOption {
  value: any;
  viewValue: string;
}

export interface InitialOptionState {
  _id: string;
  title: string;
}

export interface IMiscOption extends IOption {
  fCtrlName: string;
}

export interface IOptionCheckbox<T = string> {
  fCtrlName: T;
  viewValue: string;
  isChecked?: boolean;
}
