import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatOption } from '@interfaces';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 *  NOTE: trigger mat error on submit form by
 *  this.form.get('currControlName').updateValueAndValidity();
 */
@UntilDestroy()
@Component({
  selector: 'app-universal-chip-input',
  templateUrl: './universal-chip-input.component.html',
  styleUrls: ['./universal-chip-input.component.scss'],
})
export class UniversalChipInputComponent implements OnInit, OnDestroy {
  @ViewChild('chipList') chipList: MatChipList;

  @Input() fControl: UntypedFormControl | AbstractControl;
  @Input() chips: MatOption[] = [];

  // style
  @Input() placeholder: string;
  @Input() label: string;
  @Input() wrapperCssClass: string;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {}

  ngOnInit(): void {
    this.fControl.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.fControl.touched) return;
      this.chipList.errorState = this.fControl.invalid;
      this.chipList._blur(); // dirty hack to trigger mat-error
    });
  }

  add(ev: MatChipInputEvent) {
    const value = (ev.value || '').trim();

    if (value) this.chips.push({ value, viewValue: value });

    // Reset the input value
    if (ev.input) ev.input.value = '';
    this.fControl.setValue('');
    this.chipList.errorState = this.fControl.invalid;
  }

  remove(chip: MatOption) {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }

  ngOnDestroy() {}
}
