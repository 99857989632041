import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AUTH_ROOT, AUTHORIZED_ROOT } from '@const';

import { AuthGuard } from './helpers';

const authModule = () => import('./_pages_/auth/auth.module').then((m) => m.AuthModule);
const contentWrapperModule = () =>
  import('./_pages_/_content-wrapper/content-wrapper.module').then((m) => m.ContentWrapperModule);

const routes: Routes = [
  {
    path: AUTH_ROOT,
    loadChildren: authModule,
  },
  {
    path: AUTHORIZED_ROOT,
    loadChildren: contentWrapperModule,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: AUTHORIZED_ROOT,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
