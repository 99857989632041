import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginatedData, QueryParams } from '@interfaces';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

interface RegisterParams {
  sort: MatSort;
  paginator: MatPaginator;
  acts?: { isLoading: boolean };
  request(params: QueryParams): Observable<any>;
}

/**
 * Note: Always import directly to providers!
 */
@Injectable()
export class QueryService {
  private _destroyer$ = new Subject();
  private _onSearch$ = new Subject<PaginatedData<any>>();
  private _prevQuery = new QueryParams();

  private acts: RegisterParams['acts'];
  private request: RegisterParams['request'];
  private sort: MatSort;
  private paginator: MatPaginator;

  private readonly _params$: BehaviorSubject<QueryParams> = new BehaviorSubject(null);
  private readonly _isRegistered$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly params$: Observable<QueryParams> = this._params$.asObservable();
  readonly isRegistered$: Observable<boolean> = this._isRegistered$.asObservable();

  constructor() {
    this.subscribeToParamsChanges();
  }

  register({ sort, paginator, request, acts }: RegisterParams): Subject<PaginatedData<any>> {
    this.sort = sort;
    this.paginator = paginator;
    this.request = request;
    this.acts = acts || { isLoading: false };
    sort.sortChange.pipe(takeUntil(this._destroyer$)).subscribe(() => this.initSearch());
    paginator.page.pipe(takeUntil(this._destroyer$)).subscribe(() => this.initSearch());
    this._isRegistered$.next(true);
    return this._onSearch$;
  }

  initSearch(param?: QueryParams) {
    this.acts.isLoading = true;

    if (param?.query !== undefined) {
      this._prevQuery.query = param.query;
      this._prevQuery.searchKeys = param.searchKeys;
      this._prevQuery.filters = param.filters;
    }
    this._prevQuery.page = this.paginator.pageIndex + 1;
    this._prevQuery.sort = this.sort.direction ? this.sort.active : undefined;
    this._prevQuery.order = this.sort.direction || 'desc';
    this._prevQuery.limit = this.paginator.pageSize;

    this._params$.next(this._prevQuery);
  }

  private subscribeToParamsChanges(): void {
    this.params$
      .pipe(
        filter(Boolean),
        takeUntil(this._destroyer$),
        switchMap((query: QueryParams) => this.request(query)),
        tap(() => (this.acts.isLoading = false)),
        finalize(() => (this.acts.isLoading = false)),
      )
      .subscribe((res: PaginatedData<any>) => {
        this.paginator.length = res.totalItems;
        this.paginator.pageIndex = res.currPage - 1;
        this._onSearch$.next(res);
      });
  }

  get queryParams(): QueryParams {
    return { ...this._prevQuery };
  }

  get isRegistered(): boolean {
    return this._isRegistered$.getValue();
  }

  destroy() {
    this._destroyer$.complete();
  }
}
