<div class="universal-button-wrapper {{ wrapperCssClass }}" [matTooltip]="tooltip">
  <button
    mat-button
    [type]="htmlType"
    class="btn {{ btnCssClass }} mat-{{ styleType }} mat-{{ color }} btn-size-{{ size }}"
    [ngClass]="{
      'mat-button': styleType === 'button',
      'icon-only': !btnTitle && !['icon-button', 'mini-fab'].includes(styleType)
    }"
    [disabled]="disabled"
    (click)="onClick($event)"
  >
    <mat-icon *ngIf="icon || svgIcon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
    {{ btnTitle }}
    <span class="btn-title-suffix" *ngIf="btnTitleSuffix">{{ btnTitleSuffix }}</span>
  </button>
</div>

<input
  type="file"
  style="display: none"
  #fileInput
  [accept]="acceptFileType"
  (change)="onFileChanged($event)"
  *ngIf="btnType === 'file'"
/>
