<mat-chip-list>
  <mat-chip
    class="ellipsis"
    [ngClass]="{ 'chip-removable': isRemovable }"
    *ngFor="let item of optionsArr; let i = index"
    [removable]="isRemovable"
    (removed)="remove(item, i)"
  >
    {{ item?.viewValue || item }}
    <mat-icon matChipRemove *ngIf="isRemovable">cancel</mat-icon>
  </mat-chip>
</mat-chip-list>
