import { QueryFilters } from './query-params.interface';
import { ComplexRoute, Paginator } from '@const';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// TODO complete
export interface ListComponent<T, P = keyof T> {
  sort: MatSort;
  paginator: MatPaginator;
  Paginator: Paginator;

  dataSource: MatTableDataSource<T>;
  displayedColumns: P[];

  queryFilters: QueryFilters;
  Route?: ComplexRoute;
}
