import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optional',
})
export class OptionalPipe implements PipeTransform {
  transform(value: unknown, stub: unknown = '-'): unknown {
    if (Array.isArray(value) && !value.length) return stub;
    return value || stub;
  }
}
