import { Params, QueryParamsHandling } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { Page } from '@interfaces';

export const setActivePage = createAction('[Active Page] Set Active Page', props<{ page: Page }>());
export const activePageDefined = createAction('[Active Page] Active Page Defined');

export const updateQueryParams = createAction(
  '[Query Params] Update Query Params',
  props<{ page: Page; params: Params; handling?: QueryParamsHandling }>(),
);
