<div class="wrapper" (click)="close()">
  <div class="media-holder">
    <!--      <img *ngIf="data.type === 'image' || data.type.split('/')[0] === 'image'" [src]="data.url | sanitizer" (click)="close()">-->
    <div
      class="image"
      *ngIf="data.type === 'image' || data.type.split('/')[0] === 'image'"
      [ngStyle]="{ 'background-image': 'url(' + data.url + ')' }"
    ></div>
    <video *ngIf="data.type === 'video' || data.type.split('/')[0] === 'video'" controls>
      <source [src]="data.url | sanitizer" />
    </video>
  </div>
</div>
