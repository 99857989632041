import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    if (value == null || value === 0) {
      return '';
    }

    const totalSeconds = Math.floor(value / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';
    const formattedSeconds = seconds > 0 ? `${seconds}s` : '';

    return formattedMinutes + formattedSeconds;
  }
}
