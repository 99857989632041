import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversalPromptComponent } from './universal-prompt/universal-prompt.component';
import { PortalModule } from '@angular/cdk/portal';
import { MaterialModule } from '@modules/material.module';
import { UniversalModule } from '@modules/universal.module';

@NgModule({
  declarations: [UniversalPromptComponent],
  imports: [CommonModule, PortalModule, MaterialModule, UniversalModule],
})
export class ModalsModule {}
