import { Injectable } from '@angular/core';
import { Params, QueryParamsHandling } from '@angular/router';
import { QueryParamsPreservingApi } from '@api/query-params-preserving/query-params-preserving.api';
import { Page, QueryParamsValue } from '@interfaces';
import { QueryParamsFacade } from '@store/query-params/query-params.facade';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QueryParamsPreservingService implements QueryParamsPreservingApi {
  readonly activePage$: Observable<Page> = this.queryParamsFacade.activePage$;

  readonly activePageQueryParams$: Observable<QueryParamsValue> = this.queryParamsFacade.activePageQueryParams$.pipe(
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
  );

  constructor(private queryParamsFacade: QueryParamsFacade) {}

  setActivePage(page: Page): void {
    this.queryParamsFacade.setActivePage(page);
  }

  update(page: Page, params: Params, handling?: QueryParamsHandling): void {
    this.queryParamsFacade.update(page, params, handling);
  }
}
