import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { UniversalButtonModule } from '@components/universal-button/universal-button.module';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [CommonModule, UniversalButtonModule],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
