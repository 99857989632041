<div class="prompt-wrapper">
  <app-universal-button icon="close" color="default" mat-dialog-close class="close-btn"></app-universal-button>

  <!-- TITLE -->
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <!-- CONTENT -->
  <div mat-dialog-content>
    <p *ngIf="!data.body" class="content-text" [innerHTML]="data.textToDo"></p>
    <p *ngIf="data.body" class="content-body" [innerHTML]="data.body"></p>

    <ng-template [cdkPortalOutlet]="portal" (attached)="onTemplateAttached($event)"></ng-template>

    <app-universal-button
      [btnTitle]="data.submitTitle"
      [color]="data.submitColor"
      size="large"
      (emitClick)="onSubmit()"
      class="submit-btn"
    ></app-universal-button>

    <app-universal-button
      *ngIf="data.showCancel"
      btnTitle="Cancel"
      styleType="button"
      size="large"
      color="default"
      mat-dialog-close
      class="cancel-btn"
    ></app-universal-button>
  </div>
</div>
