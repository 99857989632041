import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';

import { MatOption, PaginatedData, QueryParams } from '@interfaces';
import { QUERY_MIN_LENGTH } from '@const';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export type AutocompleteMapFn = (q: any) => MatOption;
type InputTYpe = 'autocomplete' | 'google-places';

@UntilDestroy()
@Component({
  selector: 'app-universal-autocomplete',
  templateUrl: './universal-autocomplete.component.html',
  styleUrls: ['./universal-autocomplete.component.scss'],
})
export class UniversalAutocompleteComponent implements OnInit, OnDestroy, AfterContentInit {
  constructor() {}
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) autoInput: MatAutocompleteTrigger;

  @Input() fControl: UntypedFormControl | AbstractControl;
  @Input() searchMethod: (q: QueryParams) => Observable<PaginatedData<any>>;
  @Input() inputType: InputTYpe = 'autocomplete';

  // params
  @Input() queryParams = new QueryParams();
  @Input() queryMinLength = QUERY_MIN_LENGTH;
  @Input() useInfinityScroll = false;

  optionsArr: MatOption[] = [];

  acts = {
    isSearching: false,
    isNoMoreData: false,
  };

  // style
  @Input() placeholder: string;
  @Input() label: string;
  // @Input() disabled: string; // use by form control .disable()
  @Input() clearBtn = false;
  @Input() required = false;
  @Input() wrapperCssClass: string;
  @Input() class: string; // will give effect like <app-universal-input class="input-container short">
  @Input() attrAutocomplete: string; // chrome autocomplete attribute

  @Output() emitSelect = new EventEmitter<MatOption>();
  @Input() mapFn: AutocompleteMapFn = (it) => ({ value: it._id, viewValue: it.title });

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.inputType === 'autocomplete') {
      this.fControl.valueChanges
        .pipe(untilDestroyed(this))
        .pipe(
          filter((value: string) => {
            if (typeof value === 'object') {
              this.emitSelect.emit(value);
              return false;
            }
            return true;
          }),
        )
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (!value?.length || value?.length >= this.queryMinLength) {
            this.initSearch(new QueryParams(value));
          }
        });
    }
  }

  initSearch(queryParams: QueryParams, shouldConcat?: boolean) {
    this.acts.isSearching = true;
    this.queryParams = queryParams;

    // don't forget to apply searchKeys
    this.searchMethod(queryParams)
      .pipe(finalize(() => (this.acts.isSearching = false)))
      .subscribe((res) => {
        const data: MatOption[] = res.data.map(this.mapFn);
        this.optionsArr = shouldConcat ? this.optionsArr.concat(data) : data;
        this.acts.isNoMoreData = res.totalPages <= res.currPage;
      });
  }

  onClickInput() {
    this.autoInput.openPanel();
    if (!this.optionsArr.length && !this.queryParams.query) {
      this.initSearch(this.queryParams);
    }
  }

  onClear() {
    this.fControl.setValue('');
    this.autoInput.openPanel();
  }

  displayOption(option) {
    return option ? option.viewValue : undefined;
  }

  onScrolled() {
    if (this.useInfinityScroll && !this.acts.isSearching && !this.acts.isNoMoreData) {
      this.queryParams.page++;
      this.initSearch(this.queryParams, true);
    }
  }

  ngOnDestroy() {}
}
