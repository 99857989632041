import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ApiService, AuthResp } from '@services/api';
import { SessionService } from '@services/session';
import { Router } from '@angular/router';
import { SignInRoute } from '@const';

interface SendCodeReq {
  phone: string;
}

interface LoginReq {
  phone: string;
  code: string;
}

const BASE_AUTH_URL = 'admin/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private apiSrv: ApiService,
    private sessionSrv: SessionService,
  ) {}

  sendCode(params: SendCodeReq) {
    return this.apiSrv.post(`${BASE_AUTH_URL}/sign-in/send-code`, params);
  }

  login(params: LoginReq, isRememberMe?: boolean) {
    return this.apiSrv
      .post(`${BASE_AUTH_URL}/sign-in`, params)
      .pipe(tap((data: AuthResp) => this.sessionSrv.saveData(data, isRememberMe)));
  }

  forgotPass(params: { email: string }) {
    return this.apiSrv.post('/admin/forgot-password', params);
  }

  resetPass(params: { password: string; token: string }) {
    return this.apiSrv.post('/admin/reset-password', params);
  }

  logout() {
    this.router.navigate([SignInRoute.ROOT_PATH]).then(() => this.sessionSrv.clearData());
  }
}
