import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Page, QueryParamsValue } from '@interfaces';
import { QueryParamsState } from './query-params.reducer';
import { Params, QueryParamsHandling } from '@angular/router';
import * as fromSelectors from './query-params.selectors';
import * as fromActions from './query-params.actions';

@Injectable({ providedIn: 'root' })
export class QueryParamsFacade {
  readonly activePage$: Observable<Page> = this.store.select(fromSelectors.selectActivePage);

  readonly activePageQueryParams$: Observable<QueryParamsValue> = this.store.select(
    fromSelectors.selectCurrentPageQueryParams,
  );

  constructor(private store: Store<QueryParamsState>) {}

  setActivePage(page: Page): void {
    this.store.dispatch(fromActions.setActivePage({ page }));
  }

  update(page: Page, params: Params, handling?: QueryParamsHandling): void {
    this.store.dispatch(fromActions.updateQueryParams({ page, params, handling }));
  }
}
