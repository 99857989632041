export const AUTH_ROOT = 'auth';
export const AUTHORIZED_ROOT = '';

// AUTH
const makeAuthRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${AUTH_ROOT}/${feature}`,
});
export const SignInRoute = makeAuthRoute('sign-in');

// ROOT
const makeRootRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
});

export const DashboardRoute = makeRootRoute('dashboard');
export const ProfileRoute = makeRootRoute('');

// FEATURES
export interface ComplexRoute {
  ROOT: string;
  ROOT_PATH: string;
  DETAILS: string;
  ADD: string;
  ADD_PATH: string;
  EDIT: string;
  getDetailsPath(id: string): string;
  getEditPath(id: string): string;
}
const makeComplexRoute = (feature: string): ComplexRoute => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
  DETAILS: `:id`,
  getDetailsPath: (id) => `/${feature}/${id}`,
  ADD: `add`,
  ADD_PATH: `/${feature}/add`,
  EDIT: `:id/edit`,
  getEditPath: (id) => `/${feature}/${id}/edit`,
});

// admins
export const AdminsRoute = makeComplexRoute('admins');
// users
export const UsersRoute = makeComplexRoute('users');
// waitlist-users
export const PotentialUsersRoute = makeComplexRoute('wait-list-users');
// fav-lists
export const FavListsRoute = makeComplexRoute('fav-lists');
// fav-list-items
export const FavListItemsRoute = makeComplexRoute('fav-list-items');

// starter-kit
export const StarterKitRoute = makeComplexRoute('starter-kit');

export const AiDemoRoute = makeComplexRoute('ai-demo');

export const StatusesRoute = makeComplexRoute('statuses');

// analytics
export const ViewLogsRoute = makeComplexRoute('view-logs');
export const ViewPostLogsRoute = makeComplexRoute('view-post-logs');
