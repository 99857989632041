import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '@services/api';
import { PaginatedData, QueryParams } from '@interfaces';
import { Admin } from '..';

@Injectable({
  providedIn: 'root',
})
export class AdminsService {
  constructor(private apiSrv: ApiService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<Admin>> {
    return this.apiSrv.get('/admins/paginated', { params: queryParams });
  }

  getOne(id: string): Observable<Admin> {
    return this.apiSrv.get(`/admin/${id}`);
  }
}
